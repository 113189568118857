<template>
	<main class="page-container">
		<!-- <div class="page-header-banner">
			<picture>
				<source media="(max-width: 800px)" srcset="@/assets/img/@tmp/banner_sample_mo.jpg" />
				<source media="(min-width: 801px)" srcset="@/assets/img/@tmp/banner_sample_pc.jpg" />
				<img src="@/assets/img/@tmp/banner_sample_pc.jpg" alt="" />
			</picture>
		</div> -->
		<div class="page-body">
			<!-- 지원사업 LNB -->
			<lnb-support-biz-intrd className="" :openVisible="true" />
			<!-- //지원사업 LNB -->

			<div class="container">
				<div class="card-list-wrapper">
					<div class="page-header">
						<h3 class="page-header-title">
							<router-link :to="`/supportbiz/intrd/${intrdId}`" class="button-link-home">
								<span class="sr-only">홈으로 이동</span>
							</router-link>
							{{ intrdTit }}
						</h3>
					</div>
					<div class="list-header">
						<template>
							<div class="header-column">
								<p class="list-header-title">{{ suptExmNm }}</p>
							</div>
							<div class="header-column">
								<ul class="sort-list">
									<li class="sort-item" :class="{'is-active': (sortCd !== 'I')}"><button type="button" @click.prevent="selectSort('')">최신순</button></li>
									<li class="sort-item" :class="{'is-active': (sortCd === 'I')}"><button type="button" @click.prevent="selectSort('I')">인기순</button></li>
								</ul>
							</div>
						</template>
					</div>
					<common-list :div-class="['list-body']" :is-loading="isLoading" :is-no-result="isNoResult">
						<support-biz-intrd-card-list :items="items" :showTitle="true" :pageNo="pageNo" :sortCd="sortCd"/>
					</common-list>
				</div>
				<!-- <portal-pagination :page-name="pageName" :pageListSize="pageListSize" :total-count="totalCount" :page-size="pageSize" :page-no="pageNo" :query="queries"></portal-pagination> -->
				<portal-pagination page-name="supportBizIntrdExmList" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @getExmList="getExmList"></portal-pagination>
			</div>
		</div>
	</main>
</template>

<script>
import { ACT_GET_SPRT_BIZ_INTRD_EXM_LIST } from '@/store/_act_consts';
import { getItems, lengthCheck, setPaging, tryResCallback, queryToValue } from '@/assets/js/utils';
import SupportBizIntrdCardList from '@/components/support/SupportBizIntrdCardList';
import NoResult from '@/components/common/NoResult';
import CommonList from '@/components/common/CommonList';

import LnbSupportBizIntrd from '@/components/support/LnbSupportBizIntrd';
import PortalPagination from '@/components/PortalPaginationRoute';
import { mapGetters } from 'vuex';
import menus from '@/assets/js/menus';

export default {
	name: 'supportBizIntrdExmList',
	components: {
		LnbSupportBizIntrd,
		NoResult,
		CommonList,
		SupportBizIntrdCardList,
		PortalPagination,
	},
	data: () => ({
		//pageName: 'supportBizIntrdExmList',
    	pagingMethodName: 'getExmList',
		intrdId: 0,
		intrdTit: '',
		suptExmCd: '10',
		suptExmNm: '',
		hasMore: false,
		totalCount: 0,
		pageListSize: 10,
		pageSize: 12,
		pageNo: 1,
		listTotal: 20,
		sortCd: '',
		isNoResult: false,
		isLoading: false,
		plygrndCtgrDcd: 1,
		ctgrItems: [],
		items: [
			
		],
		tryCount: 0,
		menus: menus.filter((x) => x.code === 'information'), //모바일 중메뉴 데이터 (20220714 - hib)
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		queries() {
			const result = {};
			result.suptExmCd = this.suptExmCd;
			result.sortCd = this.sortCd;
			return result;
		},
	},
	created() {
		this.pageListSize = (this.isMobile) ? 5 : 10;

		this.intrdId = queryToValue(this.$route.params.intrdId, true, 0);
		this.suptExmCd = queryToValue(this.$route.query.suptExmCd, false, '10');
		this.pageNo = queryToValue(this.$route.query.pageNo, true, 1);
		this.sortCd = queryToValue(this.$route.query.sortCd, false, '');
		this.getExmList(this.pageNo);
	},
	methods: {
		getExmList(pageNo) {
			this.pageNo = pageNo;

			this.$store
				.dispatch(`support/${ACT_GET_SPRT_BIZ_INTRD_EXM_LIST}`, {
					intrdId: this.intrdId,
					params: {
						suptExmCd: this.suptExmCd,
						pageNo: this.pageNo,
						pageSize: this.pageSize,
						sortCd: this.sortCd
					}
				})
				.then((res) => {
					tryResCallback(this.tryCount, res, this.getExmList, this.setExmList);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		setExmList(res) {
			if (lengthCheck(res)) {
				this.items = getItems(res); //this.items.concat(getItems(res));

				if(!this.intrdTit) this.intrdTit = this.items[0].intrdTit;
				if(!this.suptExmNm) this.suptExmNm = this.items[0].suptExmNm;
			}
			setPaging(this, res);
		},
		selectSort(el) {
			if (el === 'I') {
				this.sortCd = 'I';
			} else {
				this.sortCd = '';
			}
			this.getExmList(1);
		},
		/* 중메뉴이동 (20220714 - hib) */
		click() {
			let element = document.getElementById('select_list');
			element.classList.toggle('show_list');
			let element02 = document.getElementById('select_btn');
			element02.classList.toggle('active');
		},
	},
};
</script>
